.analyzed-scene {
     &.sceene-from-server{
          z-index: 1000;
          position: fixed;
          top: 35px;
          left: 0;
          right: 0;
          bottom: 0;
          > div {
               position: relative;
               top: 50%;
               left: 50%;
               right: auto;
               bottom: auto;
               margin-right: -50%;
               transform: translate(-50%, -50%);
               height: calc(100vh - 35px - 10%);
               width: 1100px;
               border-radius: 5px;
               background: rgba(52, 52, 52, 1);
               .title{
                    background-color: #303030 !important;
               }
               .fixed-bottom {
                    background-color: #303030 !important;
               }
          }
        
     }
     .render-now {
          background-color: #343434;
      
          .fixed-bottom {
               background-color: #212121;
               border-bottom-left-radius: 5px;
               border-bottom-right-radius: 5px;
          }
          .tab-content>.tab-pane {
               height: 100%;
          }
     
          .title{
               background-color: #212121;
               border-radius: 5px 5px 0px 0px;
          
          }
          
          .close-back-btn:hover {
               background-color: #444444;
          }
          .RenderSetting {
               height: calc(100% - 38px - 38px - 45px) ;
          }
     }
}
#RenderNowNav {
     background-color: #303030;
}
.render-now {
     font-size: .9rem;
     .RenderSetting {
          padding: 1rem;
          height: calc(100vh - 38px - 38px - 46px) ;
          overflow-y: scroll;
          overflow-x: hidden;
          &::-webkit-scrollbar {
               width: 16px;
               height: 16px;
          }
          &::-webkit-scrollbar-corner {
               background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #555555;
               min-height: 40px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-track {
               background-color: transparent;
          }
          .form-label {
               width: 150px;
               margin-bottom: auto;
               margin-top: auto;
          }
     }
     .form-control {
          background-color: #545454;
          color: #FFF;
          font-size: .8rem;
          border-radius: 5px;
          padding: .5rem 1rem;
          border: none;
          &:focus {
               color: #FFF;
               background-color: #414141;
               border: none;
          }
          &::placeholder {
               color: #dddddd;
          }
          &:disabled, .form-control[readonly] {
               background-color:  rgba(255, 255, 255, 0.1) !important;
               cursor: text;
               opacity: 1;
          }
     }
   
     .input-group-text {
          background-color: transparent;
          font-size: .85rem;
          font-weight: 600;
          color: #FFF;
          border: 0 !important;
     }
     .form-select {
          border: none;
          border-radius: 5px !important;
          background-color: #545454;
          font-size: .85rem;
          padding: .47rem 1rem .47rem 1rem ;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
          color: #FFF;
          transition: all .25s;
          &:focus {
               box-shadow: none;
               background-color: #414141;
               border: none;
          }
          &:disabled {
               background-color: #444546;
               cursor: not-allowed;
               color: #666666;
               background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23666666' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
          }
     }
     option {
          background-color: #3a3a3a;
     }
     .start-btn {
          background-color: #429585;
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2) ;
          border-radius: 10px;
          color: #FFF;
          font-size: .8rem;
     }
     .start-btn:focus {
          box-shadow: none;
     }
     .start-btn:hover {
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5) ;
          background-color: #50ad9b;
     }
     .select-button {
          color: #FFF;
          border: 1px solid #8d8d8d;
     
     }
     .accordion {
          .card {     
               background-color: transparent;
               border: 1px solid #8d8d8d;
          }
          .card-header {
               border-bottom: 1px solid #8d8d8d;
               background-color: #5a5a5a;
          
          }
     }
 
     .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
     border-bottom: #3dd598 2px solid;
     background-color: transparent;
     border-radius: 0;
     color: #3dd598;
     }
     .nav-link:focus, .nav-link:hover {
     color: #3dd598;
     }
 
}

