body {
  font-family: "Josefin Sans",sans-serif,"Segoe UI","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 0.95rem;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
}
body.dark {
  background-color: #202124;
  color: #ededed;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
  color: #3dd598;
}
.bg-success-bright {
  background: rgba(61, 213, 152, 0.125) !important;
}
.bg-warning-bright {
  background: rgba(213, 172, 61, 0.125) !important;
}
.bg-secondary-bright {
  background: rgba(61, 96, 213, 0.125) !important;
}
.alert-left-border {
  border-left: 2px solid currentColor;
}
::selection {
  color: #000000;
  background: #3dd598;
}
.btn {
  color: #ededed;
}
.btn:hover {
  box-shadow: none !important;
  color: #FFF;
}
.btn:focus {
  box-shadow: none !important;
}
.btn:active {
  box-shadow: none;

}