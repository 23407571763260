#render-type {
     .nav-item {
          .nav-link {
               cursor: pointer;
               color: #FFF;
               background-color: #3c3c3c;
               border-radius: 5px;
               &:hover {
                    background-color: #4f4f4f;
                    
               }
               &.active {
                    background-color: #3dd598;
                    color: #000;
                    font-weight: bolder;
                    border: none;
               }
          }
     }
     
}