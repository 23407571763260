.analysis-btn {
     &:hover ,&:focus { 
          box-shadow: #00000050 0px 0px 10px 0px !important;
          transition: all .2s;
          border-radius: 5px;
     }
     >button {
          font-size: .9rem;
          i {
               width: 15px;
          }
     }
     .front-btn {
          transition: all .2s;
          border: none;
          padding: .5rem  1rem;
          min-width: 200px;
          text-align: start;
     }
     .dropdown-toggle{
          border: none;
          width: 40px;
          align-content: center;
          padding-bottom: 1px;
   
     }
     .dropdown-menu {
          width: 100%;
          border-radius: 0 0 5px 5px;
          border: none;
          background-color: rgba(61, 61, 61, 0.5);
          -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
          .dropdown-item {
               text-transform: uppercase;
               padding: 0.5rem 1rem;
               i {
                    width: 20px;
               }
          }
     }
}