.title-bar {
    height: 35px;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10000;
    .title-brand {
        margin-left: .75rem;
        -webkit-app-region: drag;
        .logo {
            padding-top: 5px;
            background-repeat: no-repeat;
            background-size : cover;
            height:20px;
            width:20px;
            margin-top: 10px;
            margin-bottom: auto;
            margin-right: .5rem;
        }
        .logo-text {
            font-size: 1rem;
            font-weight: 500;
            &:hover {
                transition:.25s;
                color: #3DD598;
            }
        }
        .logo-title-bar-text {
            &-super {
                @extend .logo-text;
                color: #fff;
            }
            &-renders {
                @extend .logo-text;
                color: #3DD598;
            }

        }
    }

    .dropdown-menu {
        z-index: 10003;
    }
    .titlebar-button{
        width: 45px;
        height: 35px;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        transition: .2s;
        background-repeat: no-repeat;
        background-position: center;
        color: #d3d3d3;
        padding: 2px 0px 0px 0px;
        font-size: .9rem !important;
        &:active {
            background-color: #272C36;
        }
        &:hover {
            background-color: #3d3d3d;
        }
    }

    .helpBtn {
        width: auto;
        padding: 0 1rem;
        font-size: 1rem;

        background-color: #ff00002c;

        &:hover {
            background-color: #ff000051;
        }
    }
    .userBtn {
        width: auto;
        max-width: 250px;
        min-width: 200px;
        padding: 0 1rem;
        font-size: .85rem;
        padding-top: 5px;
        text-align: left;
        .user-name {
            max-width: 200px;
        }
        &.show {
            background-color: rgba(61, 61, 61, 0.5);
            -webkit-backdrop-filter: saturate(180%) blur(20px);
            backdrop-filter: saturate(180%) blur(20px);
            box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 25%);
        }
    }
    
}


