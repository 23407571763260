.uploading-progress {
     position: fixed;
     bottom: 1.5rem;
     right: 1rem;
     background: rgb(21, 21, 21);
     border-radius: 5px;
     box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px 0px;
     width: 450px;
     transition: all .25s;

     &.hide {
          height: 0;
          padding: 0;
          overflow: hidden;
     }
     &.show {
          padding-bottom: .25rem !important;
          height: auto !important;
     }
     &:focus-visible {
          outline: none !important;
     }
     .task-list {
          padding: .5rem  1rem;
     }
     .task-item {
          > div {
               padding: .5rem;
               border-radius: 5px;
               -webkit-animation: .25s ease 0s normal forwards 1 cubic-bezier(0.075, 0.82, 0.165, 1);
               animation: .25s ease 0s normal forwards 1 cubic-bezier(0.075, 0.82, 0.165, 1);
               margin-bottom: .25rem;
               margin-top: .5rem;
               background-color: rgba(255, 255, 255, 0.2);
          }
     }
     .progress {
          height: 5px;
          background-color: rgba(119, 119, 119, 0.5);
          .fast {
               -webkit-animation: .15s linear infinite progress-bar-stripes;
               animation: .15s linear infinite progress-bar-stripes;
          }
     }
     .description {
          line-height: .9rem;
          display: flex;
          margin-top: .35rem;
          font-size: .85rem;
     }
     .cancel-btn {
          cursor: pointer;
     }
     .noAciton-trayBar {
          text-align: center;
          height: 100%;
          display: flex;
     }
     .close-notification {
          &:hover {
               background-color: rgb(89, 89, 89) !important;
          } 
     }
}