.hide-on-hover {
     height: 1.75rem;
     width: 1.75rem;
     border-radius: 50%;
     position: absolute;
     margin-top: auto;
     margin-bottom: auto;
     transition: all .25s;
     z-index: 1;
     > i {
          height: 1.75rem;
          width: 1.75rem;
          font-size: 13px;

          }
}