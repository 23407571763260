.analysis-results {
     height: 100%;
    .pointer {
          cursor: pointer;

          &:hover {
               color :bisque;
          }
    }
    .card-body {
          -webkit-user-select: text;
          user-select: text;
          max-height: 300px;
          overflow: hidden;
          overflow-y: scroll;
          &::-webkit-scrollbar {
               width: 16px;
               height: 16px;
          }
          &::-webkit-scrollbar-corner {
               background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #555555;
               min-height: 40px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-track {
               background-color: transparent;
          }
     }
     .box-info {
          background-color: #242424;
          border-radius: 10px;
          border: 1px solid #222222;
          height: 180px;
          text-align: center;
     }
}