.overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
}
.dropable {
    z-index: 2222;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: 50%;
    right: 50%;
    margin-right: -50%;             
    .dropzone-drop{
        transform: translate(-50%, -50%);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        background: rgba(79, 79, 79, 0.1);
        box-shadow: #000 0px 0px 50px 0px;
        border-radius: 15px;
        width:450px;
        height: 310px;
        padding: 1rem;
        text-align: center;
        > div {
            border-radius: 8px;
            position: relative;
            border: dashed #adadad 2px;
            height: 100%;
            width: 100%;
            > div {
                position: relative;
                bottom: 150px;
            }
        }
        span {
            position: relative;
            bottom: 135px;
            font-size: large;
            color: #adadad;
            text-transform: uppercase;
        }
    }
}

