
.info-text-modal {
     color: #b9bbbe;
}
.create-folder-text {
     user-select: text ;
}
.path-folder::placeholder {
     color:#aeaeae !important ;
}
.processors-select {
     >div {
     }
}