.analysis-item {
     background-color: #293134;
     margin-bottom: 1rem;
     margin-top: 1rem;
     margin-left: 1rem;
     border-radius: 5px;
     padding: 0.5rem 0.8rem;
     font-size: 0.95rem;
     >* > * {
          margin-top: auto;
          margin-bottom: auto;
     }
     .badge-status {
          padding: .5rem;
          min-width: 170px;
          font-size: .9rem;
          background: #242424;
          border: solid 1px;
          height: 2rem;
          line-height: 1rem;
     }
     .toggle-button {
          height: 2rem;
          width: 2rem;
          padding: 0;
          justify-content: center;
          border-radius: 50%;
          line-height: 1rem;
          margin-right: 0.8rem;
     }
   
     .analysis-more {
          transition: all .25s;
          opacity: 0;
          max-height: 0;
          &.show {
               opacity: 100;
               .content {
                    opacity: 100;
                    max-height: 500px;
                    margin-bottom: .5rem;
               }
               li , .logs {
                    transition: all .25s;
                    max-height: 0;
                    padding: 0;
                    opacity: 0;
                    
               }
               margin-top: 1rem;
               max-height: 1000px;
          }
          .content {
               transition: all .25s;
               max-height: 0;
               padding: 0;
               opacity: 0;
               background-color: #242424;
               border-radius: 5px;
               border: none;
               font-size: .9rem;
               overflow: hidden;
               overflow-y: scroll;
               &::-webkit-scrollbar {
                    width: 16px;
                    height: 16px;
               }
               &::-webkit-scrollbar-corner {
                    background-color: transparent;
               }
               &::-webkit-scrollbar-thumb {
                    background-color: #555555;
                    min-height: 40px;
               }
               &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                    border: 4px solid transparent;
                    background-clip: padding-box;
                    border-radius: 8px;
               }
               &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                    border: 4px solid transparent;
                    background-clip: padding-box;
                    border-radius: 8px;
               }
               &::-webkit-scrollbar-track {
                    background-color: transparent;
               }
               
               .title {
                    font-size: .95rem;
                    font-weight: bold;
                    padding: .5rem  0 .25rem 1rem;
                    background-color: #242424;
                    z-index: 100;
               }
               ul {
                    margin-bottom: .5rem;
                 
               }
               li , .logs {
                    user-select: text;
                    transition: all .25s;
                    max-height: 1000px;
                    opacity: 100;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
               }
          }
     }
     .btn-group {
          .btn {
               &:focus {
                    outline: 0;
                    box-shadow: none;
               }
          }
     }
}