.AppContent{
  .tabContent {
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 132px);
  }
  .nav-tabs {
    margin: 0 1rem;
    .nav-item {
      margin-right: .5rem;
      &:last-child {
        margin-right: 0;
  
       }
    }
  }
  .nav-tabs .nav-link {
     padding: 1rem 1.5rem .75rem 1.5rem;
     min-width: 200px;
     border-color: transparent;
     font-weight: bold;
     border-radius: 5px;
     font-size: 15px;
     color: #c7c7c7;
   
   }
   .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
     color: #3dd598;
     background-color: transparent;
     border-bottom: #3dd598 2px solid !important;
   }

   .nav-link:focus, .nav-link:hover {
     color: #efefef;
     background-color: rgba(82, 82, 82, 0.5);
   }
   .nav.nav-tabs{
     background-color: transparent;
     padding-top: .5rem;
     padding-bottom: 10px;
     border: none;
   }
 }