.render-collapse {
     max-height: 0px;
     opacity: 0;
     transition: all .25s;
     .card, .card-body  {
          transition: all .25s;
          max-height: 0;
          opacity: 0;
          display: none;
     }
     .body-content {
          overflow: hidden;
          overflow-y: scroll;
          max-height: 520px;
          &::-webkit-scrollbar {
               width: 16px;
               height: 16px;
          }
          &::-webkit-scrollbar-corner {
               background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #555555;
               min-height: 40px;
          
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-track {
               background-color: transparent;
          }
          user-select: text;
          padding: .5rem ;
          border-radius: 5px;
          background-color: #3a3a3a;
     }
     &.show {
     max-height: 700px ;
     opacity: 1;
     .card, .card-body{
          opacity: 1;
          max-height: 600px;
          display: block;
     }
     .card-body {
          padding: .5rem;
          .nav.nav-pills {
               .nav-link {
                    color: #FFFFFF;
                    min-width: 150px;
                    text-align: center;
                    margin-right: .5rem;
                    font-size: small;
                    text-transform: uppercase;
                    &.active {
                         background-color: #3dd598;
                         color: #0A0A0A;
                    }
               }
          }
          .collapse > div {
               overflow: hidden;
               overflow-y: scroll;
               max-height: 310px;
               &::-webkit-scrollbar {
                    width: 16px;
                    height: 16px;
               }
               &::-webkit-scrollbar-corner {
                    background-color: transparent;
               }
               &::-webkit-scrollbar-thumb {
                    background-color: #555555;
                    min-height: 40px;
               
               }
               &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                    border: 4px solid transparent;
                    background-clip: padding-box;
                    border-radius: 8px;
               }
               &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                    border: 4px solid transparent;
                    background-clip: padding-box;
                    border-radius: 8px;
               }
               &::-webkit-scrollbar-track {
                    background-color: transparent;
               }
          }
     }
}
}