.file-explorer {
     height: calc(100% - 150px);
     overflow-y: scroll;
     &::-webkit-scrollbar {
          width: 16px;
          height: 16px;
     }
     &::-webkit-scrollbar-corner {
          background-color: transparent;
     }
     &::-webkit-scrollbar-thumb {
          background-color: #555555;
          min-height: 40px;
     }
     &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
          border: 4px solid transparent;
          background-clip: padding-box;
          border-radius: 8px;
     }
     &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
          border: 4px solid transparent;
          background-clip: padding-box;
          border-radius: 8px;
     }
     &::-webkit-scrollbar-track {
          background-color: transparent;
     }
     .file-item {
          padding: 5px;
          transition: all .25s;
          border-bottom: 1px solid #3d3d3d;
          &:hover {
               background-color: #20c997;
               color: #000;
               cursor: pointer;
               .file-icon {
                    box-shadow: 0px 0px 5px 0px #000000;
               }
          }
     }
     .file-name,.file-size,.file-modified {
          line-height: 28px;
          font: menu;
     }
     .file-icon {
          border-radius: 50px;
          > * {
               background-size: cover;
               background-repeat: no-repeat;
               width: 1.75rem;
               height: 1.75rem;
          }
     }
}