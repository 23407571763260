.account-title-bar {
     .dropdown-menu {
          transform: translate(0px, 35px) !important;
          width: 100%;
          border-radius: 0 0 5px 5px;
          border: none;
          background-color: rgba(61, 61, 61, 0.5);
          -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
          box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 25%);
          .dropdown-item {
               text-transform: uppercase;
               color: #dddddd;
               padding: .5rem 1rem;
               i {
                    width: 15px;
                    text-align: center;
               }
          }
     }
     .current-credit{
          font-size: .9rem;
          color: #20c997;
          i {
               font-size: .785rem;
          }
     }
}