.loading {
     z-index: 10;
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     -webkit-backdrop-filter: blur(15px) !important;
     backdrop-filter: blur(15px) !important;
     -webkit-animation: .25s ease 0s normal forwards 1 fadein;
	animation: .25s ease 0s normal forwards 1 fadein;
}
.login-form {
     background-color: #000;
     color: #FFF;
     position: relative;
     height: 100vh;
     width: 100%;
     .form-control {
          color: #ffffff;
          border: none;
          background-color: #19191f;
     }
     .form-control:hover {
          background-color: #19191f;
      }
     .form-control:focus {
          color: #ffffff;
          background-color: #19191d;
          border-color: transparent;
          outline: 0;
          box-shadow:none;
     }
     .form-floating>label {
          color: rgb(172, 172, 172);
     }
}
.login-form .content {
     padding-right: var(--bs-gutter-x,.75rem);
     padding-left: var(--bs-gutter-x,.75rem);
     margin: auto;
     width: 80%;
}
.login-form .form-check-input {
     border: 2px solid #3dd598!important;
     border-radius: 50px;
     background-color: transparent;
     cursor: pointer;

}

.login-form .form-check-label {
     cursor: pointer;
}
.login-form .form-check-label ,.login-form .sign-up ,.login-form .forgot-password{
     font-size: .9rem;
}
.login-form .footer {
     bottom: 3rem;
     font-size: small;
     font-weight: 100;
}
.login-form .server {
     background-color: rgba(255, 255, 255, 0.2);
     backdrop-filter: saturate(180%) blur(20px);
     border-radius: 5px;
     padding: 7px;
}
.login-form .server i {
     font-weight: 700;
     margin:0px .25rem;
}
.login-form .server .server-name {
     font-weight: 700;
     margin:0px .5rem;
}
.login-form .server .server-quality {
     font-weight: 700;
     color: #86e3ce;
     margin: 0 .25rem;
}
.login-form {
     .titlebar-button{
          font:icon;
          width: 45px;
          height: 35px;
          border: none;
          outline: none;
          background-color: transparent;
          cursor: pointer;
          transition: .2s;
          background-repeat: no-repeat;
          background-position: center;
          color: #d3d3d3;
          padding: 2px 0px 0px 0px;
          &:active {
              background-color: #272C36;
          }
          &:hover {
              background-color: #3d3d3d;
          }
      }
      .closeBtn {
          &:hover {
              background-color: #cc0000;
          } 
      }
}
