.render-without-analyze {
     height: calc(100% - 38px - 38px - 10px);
     overflow-y: scroll;
     .fixed-bottom {
          background-color: #303030;
     }
     &::-webkit-scrollbar {
          width: 16px;
          height: 16px;
     }
     &::-webkit-scrollbar-corner {
          background-color: transparent;
     }
     &::-webkit-scrollbar-thumb {
          background-color: #a7a7a7;
          min-height: 40px;
     }
     &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
          border: 4px solid transparent;
          background-clip: padding-box;
          border-radius: 8px;
     }
     &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
          border: 4px solid transparent;
          background-clip: padding-box;
          border-radius: 8px;
     }
     &::-webkit-scrollbar-track {
          background-color: transparent;
     }
  
     .nav-tabs .nav-link {
          padding: .8rem 1.5rem;
          border: none;
          font-weight: bold;
          border-radius: 5px;
          font-size: .9rem;
          margin-right: 1rem;
          color: #ededed;
     }
     .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
          color: #383838 ;
          background-color: #3dd598;
          border-radius: 5px;
     }
     .nav-link:focus, .nav-link:hover {
          color: #FFF;
          background-color: #383838;
          border-radius: 5px;
     }
     .nav.nav-tabs{
          border: none;
     }
     .dropdown-menu {
          margin-top: .5rem !important;
          padding: 0;
          max-height: 200px;
          overflow-x: hidden;
          overflow-y: scroll;
          background-color: rgba(61, 61, 61, 0.5);
          -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
          width: 100%;
          &::-webkit-scrollbar {
               width: 16px;
               height: 16px;
             }
             &::-webkit-scrollbar-corner {
                 background-color: transparent;
             }
             &::-webkit-scrollbar-thumb {
                 background-color: #a7a7a7;
                 min-height: 40px;
             }
             &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                 border: 4px solid transparent;
                 background-clip: padding-box;
                 border-radius: 8px;
             }
             &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                 border: 4px solid transparent;
                 background-clip: padding-box;
                 border-radius: 8px;
             }
             &::-webkit-scrollbar-track {
                 background-color: transparent;
             }
     }
     .list-group-item {
          background-color: transparent;
          color: #FFF;
          font-size: .9rem;
          font: menu;
          border-left: none;
          border-radius: 0;
     }
}