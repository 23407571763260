.render-status {
     .info {
          text-transform: uppercase;
          color: #d3d3d3;
          font-size: .8rem;
     }
     .progress {
          height: 5px;
          background-color: #212529;
          .progress-bar-animated {
               -webkit-animation: .15s linear infinite progress-bar-stripes;
               animation: .15s linear infinite progress-bar-stripes;
          }

     }
}