.quick-tips {
     display: flex;
     margin: 0 1rem;
     margin-bottom: .5rem;
     -webkit-animation: .35s ease 0s normal forwards 1 fadein;
	animation: .35s ease 0s normal forwards 1 fadein;
     .content {
          user-select: text;
          transition: all .25s ;
          &.show {
               height: 135px;
               overflow: hidden;
          }
          &.hide {
               height: 20px;
               overflow: hidden;
          }
     }
     strong {
          cursor: pointer;
     }
     .toggle-button {
          width: 20px ;
          height: 20px;
          margin-left: auto;
          cursor: pointer;
          i {
               font-size: 20px;
          }
     }
}
.cloud-storage {
     background-color: #424242;
     border-radius: 5px;
     margin: 0 1rem;
     -webkit-animation: .35s ease 0s normal forwards 1 fadein;
	animation: .35s ease 0s normal forwards 1 fadein;
     .search-bar {
          padding: .5rem 12px;
          display: flex;
          .form-control {
               font-size: .7rem;
               background-color: #5e5e5e;
               box-shadow: rgba(19, 23, 24, 0.5) 0px 0px 5px 0px;
               &:hover {
                    background-color: #4b4b4b ;
                    box-shadow: rgba(19, 23, 24, 0.5) 3px 3px 10px 0px !important;
                    color: #ffffff;
                  }
                  &:focus {
                    background-color: #4b4b4b ;
                    box-shadow: rgba(19, 23, 24, 0.5) 3px 3px 10px 0px !important;
                    color: #ffffff;
                  }
          }
          .toggle-space {
               background-color: #585858;
               box-shadow: rgba(19, 23, 24, 0.5) 0px 0px 5px 0px;
               border: none;
               font-size: .8rem;
               text-transform: uppercase;
               color: #e4e4e4;
               transition: all .25s;
               margin-right: .5rem;
               font:icon;
               &:hover {
                 background-color: #4b4b4b ;
                 box-shadow: rgba(19, 23, 24, 0.5) 3px 3px 10px 0px !important;
                 color: #ffffff;
               }
          }
          .upload-button {
               background-color: #585858;
               box-shadow: rgba(19, 23, 24, 0.5) 0px 0px 5px 0px;
               border: none;
               font-size: .8rem;
               text-transform: uppercase;
               color: #e4e4e4;
               transition: all .25s;
               margin-right: .5rem;
               &:hover {
                 background-color: #4b4b4b ;
                 box-shadow: rgba(19, 23, 24, 0.5) 3px 3px 10px 0px !important;
                 color: #ffffff;
               }
               &:disabled  {
                    color: #3a3a3a !important;
                    cursor: not-allowed;
                    &:hover{
                         color: rgba(16, 16, 16, 0.3);
                         background-color: #585858 ;
                    }
               }
          }
     }
}
