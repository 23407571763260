.plugins-manager {
     height: 100%;
     border-radius: 5px;
     -webkit-animation: .35s ease 0s normal forwards 1 fadein;
	animation: .35s ease 0s normal forwards 1 fadein;
     margin: 0 1rem;
     .plugin-topbar {
          margin: 0 .5rem .5rem 0 ;
          .alert {
               user-select: text;
          }
     }
     .plugin-thumbnail {
          margin-top: auto;
          margin-bottom: auto;
          margin-right: .5rem;
          > * {
               background-size: cover;
               background-repeat: no-repeat;
               width: 2.5rem;
               height: 2.5rem;
          }
     }
     .plugin-title {
          height: 40px;
          div {
               padding-top: 4.5px;
               color: #FFF;
               font-size: medium;
               line-height: 1;
          }
          span {
               color: #c0c0c0;
               font-size: small;
          }
     }
     .list-plugins{
          height: calc(100vh - 180px);
          overflow: hidden;
          overflow-y: scroll;
          &::-webkit-scrollbar {
               width: 16px;
               height: 16px;
          }
          &::-webkit-scrollbar-corner {
               background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #555555;
               min-height: 40px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-track {
               background-color: transparent;
          }
        
          .plugin-item {
               border-radius: 5px;
               margin: 0 .5rem .5rem 0 ;
               background-color: #383838;
               padding: 1rem;
               .plugin-item-body {
                    display: flex;
                    .preference-button {
                         box-shadow: rgba(19, 23, 24, 0.5) 0px 0px 5px 0px;
                         background-color: #1b1b1b;
                         border: none;
                         border-radius: 5px;
                         width: 100px;
                         height: 35px;
                         line-height: 23px;
                         font-size: .9rem;
                         &:hover {
                              background-color: #272727 !important;
                              border:none;
                         }
                         &:focus {
                              outline: 0;
                              box-shadow: none;
                         }
                    }
               }
          }
          .children-item {
               &:first-child {
                    margin-top: 1rem;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
               }
               &:last-child {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
               }
               background-color: #2e2e2e;
               transition: all .2s;
               display: flex;
               &:hover {
                    background-color: #1b1b1b;
               }
               &.active {
                    background-color: #00512a;
               }
               .children-item-body {
                    cursor: pointer;
                    height: 55px;
                    padding: .5rem 0rem .5rem 1rem;
                    display: flex;
                    .children-title {
                         color: #FFF;
                         font-size: .95rem;
                         line-height: 1;
                         padding-top: 4.5px;
                    }
                    .children-detail {
                         color: #d0d0d0;
                         font-size: .9rem;
                    }
          
               }
               .dropdown-menu {
                    border: none;
                    background-color: #3d3d3d;
                    box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 25%);
                    .dropdown-item {
                         text-transform: uppercase;
                         color: #dddddd;
                         padding: .5rem 1rem;
                         i {
                              width: 15px;
                              text-align: center;
                         }
                    }
               }
          }
     }
}