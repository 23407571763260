.camera {
 
     .dropdown-menu {
          padding: 0;
          max-height: 200px;
          overflow-x: hidden;
          overflow-y: scroll;
          background-color: rgba(61, 61, 61, 0.5);
          -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
          width: 100%;
          &::-webkit-scrollbar {
               width: 16px;
               height: 16px;
             }
             &::-webkit-scrollbar-corner {
                 background-color: transparent;
             }
             &::-webkit-scrollbar-thumb {
                 background-color: #a7a7a7;
                 min-height: 40px;
             }
             &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                 border: 4px solid transparent;
                 background-clip: padding-box;
                 border-radius: 8px;
             }
             &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                 border: 4px solid transparent;
                 background-clip: padding-box;
                 border-radius: 8px;
             }
             &::-webkit-scrollbar-track {
                 background-color: transparent;
             }
     }
     .list-group-item {
          background-color: transparent;
          color: #FFF;
          font-size: .9rem;
          font: menu;
          border-left: none;
          border-radius: 0;
     }
     .form-check-input {
        background-color: #242424;
        &:checked {
          background-color: #3dd598;
          border-color: #3dd598;
        }
        &:focus {
          box-shadow: none;
          border-color:transparent;
        }
      }
}