.dropable-zone {
  display: flex;
  padding: 4rem;
  .add-files-button {
    background-color: #585858;
    box-shadow: rgba(19, 23, 24, 0.5) 0px 0px 5px 0px;
    border: none;
    border-radius: 50px;
    width: 250px;
    height: 35px;
    line-height: 35px;
    font-size: .8rem;
    &:hover {
      background-color: #4b4b4b ;
      transition: all .25s;
      color: #3dd598;
    }
  }
  .form-check {
    .form-check-input {
      background-color: #242424;
      &:checked {
        background-color: #3dd598;
        border-color: #3dd598;
      }
      &:focus {
        box-shadow: none;
        border-color:transparent;
      }
    }
  }
  .dropzone {
    box-shadow: rgba(19, 23, 24, 0.5) 0px 0px 5px 0px;
    background-color: #242424;
    border-radius: 10px;
    border: 1px solid #222222;
    height: 180px;
    text-align: center;
    line-height: 180px;
  }
}
