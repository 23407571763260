
.tray-window {
    width: 100%;
    height: 650px;
    color : #FFFFFF !important;
    .title-brand {
        height : 70px;
        width: 100%;
        background-color : #000000;
        text-align : center ;
        align-items : center;
        display: flex;
        justify-content : center;
        color: #3dd598;
        .logo-text {
            font-size: .9rem;
            font-weight: 700;
            &:hover {
                transition:.25s;
                color: #3DD598;
            }
        }
        .logo-title-bar-text {
            &-super {
                @extend .logo-text;
                color: #fff;
            }
            &-renders {
                @extend .logo-text;
                color: #3DD598;
            }
            &-farm {
                @extend .logo-text;
                color: #3DD598;
            }

        }
    }
    .tray-item {
        display: flex;
        margin: 1rem 0;

        .item-thumbnail {
            border-radius: 50px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 1rem;
            margin-right: 1rem;
            > * {
                 
                 background-size: cover;
                 background-repeat: no-repeat;
                 width: 2.5rem;
                 height: 2.5rem;
            }
       }
       .item-info {
           >div {
            width: 260px; 
           }
           .filename {
                font-size: .85rem;
                font-weight: 500;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis; 
         
            }
           .path , .modified {
                font-size: .8rem;
                color: #c0c0c0;
                white-space: nowrap; 
                
                overflow: hidden;
                text-overflow: ellipsis; 
         
            }   
       }
    }
    .rendering-item {
        display: flex;
        margin-top: 1rem;
        .item-thumbnail {
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 1rem;
            margin-right: 1rem;
            > * {
                background-size: cover;
                background-repeat: no-repeat;
                width: 2rem;
                height: 2rem;
            }
       }
       .item-info {
           .filename {
                font-weight: 500;
                white-space: nowrap; 
                width: 230px; 
                overflow: hidden;
                text-overflow: ellipsis; 
         
            }
            .path {
                .progress{
                    height: 5px ;
                    border-radius: 0;
                    .fast {
                        -webkit-animation: .15s linear infinite progress-bar-stripes;
                        animation: .15s linear infinite progress-bar-stripes;
                   }
                }
          
            }
           .modified {
                font-size: .8rem;
                color: #9e9e9e;
                white-space: nowrap; 
                width: 280px; 
                overflow: hidden;
                text-overflow: ellipsis; 
                margin-top: .2rem;
                .cancel-btn {
                    cursor: pointer;
                }
            }   
       }
    }
    .tray-windows-body {
        position: relative;
        height: calc(100vh - 200px);
        width: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 16px;
            height: 16px;
       }
       &::-webkit-scrollbar-corner {
            background-color: transparent;
       }
       &::-webkit-scrollbar-thumb {
            background-color: #555555;
            min-height: 40px;
       }
       &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
            border: 4px solid transparent;
            background-clip: padding-box;
            border-radius: 8px;
       }
       &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
            border: 4px solid transparent;
            background-clip: padding-box;
            border-radius: 8px;
       }
       &::-webkit-scrollbar-track {
            background-color: transparent;
       }
    }
    .tray-window-footer {
        height: 70px;
        .tray-footer-button {
            display: flex;
            button {
                padding: 0 1rem ;
                height: 70px;
                width: 100%;
                border: 1px solid #373737;
                border-top: none;
                transition: all .25s;
                background-color: #585858;
                font-size: .8rem;
                color: #e4e4e4;
                &:hover {
                    background-color: #4b4b4b ;
                    color: #ffffff;
                }
                &:disabled  {
                    color: #3a3a3a !important;
                    cursor: not-allowed;
                    &:hover{
                        color: rgba(16, 16, 16, 0.3);
                        background-color: #585858 ;
                    }
                }
            }
        }
    }
    .noAciton-trayBar {
        text-align: center;
        height: calc( 100% - 140px);
        display: flex;
    }
}
