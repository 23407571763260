.empty-render-job {}
.quick-tips-for-newb {
     .item {
          .figure {
               filter: brightness(.8);

               border-radius: 5px;
               transition: all .25s;
               box-shadow: rgba(0, 0, 0, 0.163) 5px 10px 10px 0px ;
               background-color: #3d3d3d33;
               &:hover {
                    box-shadow: rgba(16, 16, 16, 0.496) 5px 10px 10px 0px ;
                    transform: scale(1.05) ;
                    filter: brightness(1);
                    cursor: pointer;
                    .figure-caption {
                         color: #cbcbcb;
                    }
               }
          }
          .figure-caption {
               color: rgb(202, 202, 202);
               padding: .25rem .25rem .5rem .25rem;
          }
        
     }
}
