.quick-tips-renderJobs {
     display: flex;
     margin: 0 1rem .5rem 1rem;
     -webkit-animation: .35s ease 0s normal forwards 1 fadein;
	animation: .35s ease 0s normal forwards 1 fadein;
     
     .input-group {
          .input-group-text {
               background-color: #6b6b6b !important;
               border-left: none ;
               border-bottom: none;
               border-top: none;
               border-right: #8a8a8a 2px solid !important;
          }
          .open-folder-btn {
               background-color: #287555;
          }
          .change-folder-btn {
               background-color: #1e4134;
          }
          button:hover {
               color: #FFF;
               background-color: #648376;
          }
     }
     .content {
          transition: all .25s ;
          width: calc(100% - 40px);
          ul {
               padding-left: 15px;
               user-select: text;
          }
          &.show {
               height: 40px;
               overflow: hidden;

          }
          &.hide {
               height: 20px;
               overflow: hidden;
          }
     }
     strong {
          cursor: pointer;
     }
     .toggle-button {
          width: 20px ;
          height: 20px;
          margin-left: auto;
          cursor: pointer;
          i {
               font-size: 20px;
          }
     }
}
.render-jobs {
     .more-btn {
          &:hover ,&:focus { 
               box-shadow: #00000050 0px 0px 10px 0px !important;
               transition: all .2s;
               border-radius: 5px;
          }
          >button {
               font-size: .9rem;
             
          }
          .front-btn {
               transition: all .2s;
               border: none;
               padding: .5rem  1rem;
               min-width: 210px;
               text-align: start;
          }
          .dropdown-toggle{
               border: none;
               width: 40px;
               align-content: center;
               padding-bottom: 1px;
          }
          .dropdown-menu {
               width: 100%;
               border-radius: 0 0 5px 5px;
               border: none;
               background-color: rgba(61, 61, 61, 0.5);
               -webkit-backdrop-filter: saturate(180%) blur(20px);
               backdrop-filter: saturate(180%) blur(20px);
               .dropdown-item {
                    text-transform: uppercase;
                    padding: 0.5rem 1rem;
               }
          }
     }
     -webkit-animation: .35s ease 0s normal forwards 1 fadein;
	animation: .35s ease 0s normal forwards 1 fadein;
     background-color: #424242;
     border-radius: 5px;
     margin: 0 1rem;
     &.quick-tips-show {
          transition: all .25s ;
          height: calc(100% - 85px);
     }
     &.quick-tips-hide {
          transition: all .25s ;
          height: calc(100% - 65px);
     }
     
     overflow: hidden;
     overflow-y: scroll;
  
     &::-webkit-scrollbar {
          width: 16px;
          height: 16px;
     }
     &::-webkit-scrollbar-corner {
          background-color: transparent;
     }
     &::-webkit-scrollbar-thumb {
          background-color: #555555;
          min-height: 40px;
       
     }
     &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
          border: 4px solid transparent;
          background-clip: padding-box;
          border-radius: 8px;
     }
     &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
          border: 4px solid transparent;
          background-clip: padding-box;
          border-radius: 8px;
     }
     &::-webkit-scrollbar-track {
          background-color: transparent;
     }
     .render-header {
          display: flex;
          padding: .5rem 1rem;
          border-radius: 5px;
          background-color: #242424;
          margin-bottom: .5rem;
          > * {
               font-weight: 500;
               font-size: .95rem;
          }
     }
     .render-body {
          .render-item {
               background-color: #293134;
               margin-bottom: 1rem;
               margin-top: 1rem;
               margin-left: 1rem;
               border-radius: 5px;
               padding: .5rem .8rem;
               font-size: .95rem;
               >* > * {
                    margin-top: auto;
                    margin-bottom: auto;
               }
               .toggle-button {
                    height: 2rem;
                    width: 2rem;
                    padding: 0;
                    justify-content: center;
                    border-radius: 50%;
                    line-height: 1rem;
                    margin-right: .8rem;
                    &:focus {
                         outline: 0;
                         box-shadow: none;
                    }
               }
               .render-more {
                    transition: all .25s;
                    margin: .5rem;
                    .more-header {
                         margin-bottom: .5rem;
                         padding-bottom: .25rem;
                    }
                    .more-body {
                    }
               }
               .btn-group {
                    .btn {
                         &:focus {
                              outline: 0;
                              box-shadow: none;
                         }
                    }
               }
          }
     }
}