.scene-analysis {
     -webkit-animation: .35s ease 0s normal forwards 1 fadein;
	animation: .35s ease 0s normal forwards 1 fadein;
     background-color: #424242;
     border-radius: 5px;
     margin: 0 1rem;
     height: calc(100% - 50px);
     overflow: hidden;
     overflow-y: scroll;
     &::-webkit-scrollbar {
          width: 16px;
          height: 16px;
     }
     &::-webkit-scrollbar-corner {
          background-color: transparent;
     }
     &::-webkit-scrollbar-thumb {
          background-color: #555555;
          min-height: 40px;
     }
     &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
          border: 4px solid transparent;
          background-clip: padding-box;
          border-radius: 8px;
     }
     &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
          border: 4px solid transparent;
          background-clip: padding-box;
          border-radius: 8px;
     }
     &::-webkit-scrollbar-track {
          background-color: transparent;
     }
}